import notify from '@core/store/notify';
import i18n from '@/lang';
import language from '@core/store/language';
import userSettings from '@core/store/userSettings';
import permissions from '@core/store/permissions';

export default {
  namespaced: true,
  state: {
    tenantModules: [],
  },
  getters: {
    token: (s, g, rs, rg) => rg['oidcStore/oidcAccessToken'],
    user: (s, g, rs, rg) => rg['oidcStore/oidcUser'],
    hubUrl: () => '',
    plantName: (s, g, rs) => plantId => {
      if (!plantId || !rs.structure.plants) return '';
      const p = rs.structure.plants.find(x => x.id.toLowerCase() === plantId.toLowerCase());
      return p ? p.name : '';
    },
    serviceWorker: () => null,
    tenantModules: s => s.tenantModules,
  },
  actions: {
    signOut(ctx) {
      ctx.dispatch('oidcStore/signOutOidc', null, { root: true });
    },
    showNotifications() {},
  },
  mutations: {
    setModules(s, list) {
      s.tenantModules = list;
    },
  },
  modules: {
    permissions,
    userSettings,
    notify,
    language: language(i18n),
  },
};
