<template>
  <div>
    <h1
      class="text-muted mb-4"
      style="cursor: pointer;"
      @click="goBack"
    >
      <i class="fas fa-circle-chevron-left" />
    </h1>

    <BRow>
      <BCol lg="6">
        <TaxonomyInfoForm
          :description.sync="editTaxonomy.description"
          :code.sync="editTaxonomy.code"
          :namespace-id="editTaxonomy.namespaceId"
          :tags.sync="editTaxonomy.tags"
          code-input-disabled
          namespace-id-input-disabled
        />
      </BCol>
    </BRow>

    <hr>

    <TaxonomyLevelsTree
      v-model="taxonomyLevels"
      show-edit-levels-link
      :disabled="taxonomyLevelsLocked"
      @levels-unlock="handleLevelsUnlock"
    />

    <hr>

    <TaxonomyNodesTree
      v-if="taxonomyLevelsLocked"
      v-model="taxonomyNodes"
      :levels-graph="levelsGraph"
      :nodes-graph="nodesGraph"
    />

    <hr>

    <div class="mt-4">
      <BButton
        class="btn-icon"
        @click="showRemoveModal"
      >
        <i class="fas fa-trash-can" />
      </BButton>

      <BButton
        class="rounded-pill px-5 ml-2"
        @click="goBack"
      >
        {{ $t('general.cancel') }}
      </BButton>

      <BButton
        class="rounded-pill px-5 ml-2"
        variant="success"
        :disabled="nextStepDisabled"
        @click="handleNextStep"
      >
        <i class="fas fa-check mr-1" />
        {{ $t('general.save') }}
      </BButton>
    </div>

    <TaxonomyRemoveModal
      @cancel="handleRemoveModalCancel()"
      @confirm="handleRemoveModalConfirm()"
    />
  </div>
</template>

<script>
import TaxonomyInfoForm from '@/components/taxonomies/form/TaxonomyInfoForm';
import TaxonomyLevelsTree from '@/components/taxonomies/TaxonomyLevelsTree';
import TaxonomyNodesTree from '@/components/taxonomies/TaxonomyNodesTree';
import Graph from '@core/utils/Graph';
import TaxonomyRemoveModal from '@/components/taxonomies/modals/TaxonomyRemoveModal';
import { mapActions, mapGetters } from 'vuex';

export default {
  inject: [
    'refreshTaxonomies',
  ],
  data: () => ({
    editTaxonomy: {},
    taxonomyLevels: [],
    taxonomyNodes: [],
    taxonomyLevelsLocked: true,
  }),
  components: {
    TaxonomyRemoveModal,
    TaxonomyNodesTree,
    TaxonomyLevelsTree,
    TaxonomyInfoForm,
  },
  computed: {
    ...mapGetters([
      'taxonomies',
    ]),
    thisTaxonomyId() {
      return this.$route.params.id;
    },
    thisTaxonomy() {
      return this.taxonomies.find(t => t.id === this.thisTaxonomyId);
    },
    levelsGraph() {
      const graph = this.taxonomyLevels.reduce((acc, curr) => {
        acc.edges.push({
          from: null,
          to: curr.data.id,
        });

        const rec = node => {
          acc.nodes.push(node.data);
          acc.edges.push(...node.children.map(c => ({
            from: node.data.id,
            to: c.data.id,
          })));
          if (node?.children?.length) {
            node.children.forEach(c => rec(c));
          }
        };
        rec(curr);

        return acc;
      }, {
        edges: [],
        nodes: [],
      });
      return new Graph(
        'levels',
        graph.nodes,
        graph.edges,
        null,
      );
    },
    nodesGraph() {
      const graph = this.taxonomyNodes.reduce((acc, curr) => {
        acc.edges.push({
          from: null,
          to: curr.data.id,
        });

        const rec = node => {
          acc.nodes.push(node.data);
          acc.edges.push(...node.children.map(c => ({
            from: node.data.id,
            to: c.data.id,
          })));
          if (node?.children?.length) {
            node.children.forEach(c => rec(c));
          }
        };
        rec(curr);

        return acc;
      }, {
        edges: [],
        nodes: [],
      });
      return new Graph(
        'nodes',
        graph.nodes,
        graph.edges,
        null,
      );
    },
    nextStepDisabled() {
      if (this.taxonomyLevelsLocked) {
        const nodeLengthCheck = this.nodesGraph.nodeList().length === 0;
        const nodeValidCheck = this.nodesGraph.nodeList()
          .some(n => n.code === ''
            || n.taxonomyLevelId === '');
        const nodesValidLevelCheck = this.nodesGraph.nodeList()
          .some(n => !this.levelsOfNode(n)
            .find(({ id }) => id === n.taxonomyLevelId));
        return nodeLengthCheck
          || nodeValidCheck
          || nodesValidLevelCheck;
      }

      const codeCheck = this.editTaxonomy.code === '';
      const plantIdCheck = this.editTaxonomy.namespaceId === '';
      const tagsCheck = this.editTaxonomy.tags.length === 0;
      const levelsLengthCheck = this.levelsGraph.nodeList().length === 0;
      const levelsValidCheck = this.levelsGraph.nodeList()
        .some(n => n.code === '');
      return codeCheck
        || plantIdCheck
        || tagsCheck
        || levelsLengthCheck
        || levelsValidCheck;
    },
  },
  methods: {
    ...mapActions([
      'getTaxonomiesById',
      'removeTaxonomy',
      'updateTaxonomy',
      'createTaxonomyLevel',
      'updateTaxonomyLevel',
      'removeTaxonomyLevel',
      'createTaxonomyNode',
      'updateTaxonomyNode',
      'removeTaxonomyNode',
    ]),
    levelsOfNode(node) {
      const parentId = this.nodesGraph.parent(node.id);
      if (parentId) {
        const parent = this.nodesGraph.node(parentId);
        const levelsIds = this.levelsGraph.childrenOf(parent.taxonomyLevelId);
        return levelsIds.map(id => this.levelsGraph.node(id));
      }
      const depth = this.nodesGraph.depth(node.id);
      return this.levelsGraph.nodesInDepth(depth)
        .map(id => this.levelsGraph.node(id));
    },
    goBack() {
      const rootId = this.$route.path.split('/')[1];
      this.$router.push(`/${rootId}/taxonomies`);
    },
    parseStructure(items) {
      const graph = new Graph(
        'graph',
        items,
        items.map(({
          id,
          parentId,
        }) => ({
          from: parentId,
          to: id,
        })),
        null,
      );
      const rec = node => {
        const children = graph.childrenOf(node.id)
          .map(id => graph.node(id))
          .map(rec);
        return {
          data: {
            ...node,
          },
          children,
          state: {
            selectable: false,
            expanded: true,
          },
        };
      };
      return graph.nodeList()
        .filter(({ parentId }) => parentId === null)
        .map(rec);
    },
    handleLevelsUnlock() {
      this.taxonomyLevelsLocked = false;
      this.$bvModal.hide('taxonomy-levels-edit-warning-modal');
    },
    async submit() {
      this.$store.commit('setLoadingTaxonomies', true);

      try {
        const taxonomyId = this.editTaxonomy.id;

        // Get current taxonomy for reference
        const { data: taxonomies } = await this.getTaxonomiesById({
          params: {
            query: {
              ids: [taxonomyId],
            },
          },
        });

        let updatedTaxonomy = taxonomies[0];

        // Update taxonomy
        const updateTaxonomyForm = {
          description: this.editTaxonomy.description,
          namespaceId: this.editTaxonomy.namespaceId,
          code: this.editTaxonomy.code,
          isHierarchical: true,
          tags: this.editTaxonomy.tags,
        };
        await this.updateTaxonomy({
          data: updateTaxonomyForm,
          params: {
            taxonomyId,
          },
        });

        // Update and create levels
        const reqLevel = async (innerLevelId, realParentId) => {
          const levelObj = this.levelsGraph.node(innerLevelId);
          const levelForm = {
            parentId: realParentId,
            code: levelObj.code,
            description: levelObj.description,
            policyType: 1, // None = 0, CanAddItem = 1, Roslyn = 2 TODO: level policies
            policy: levelObj.policy,
          };

          const createLevel = Number(levelObj.id)
            .toString() === levelObj.id;

          if (createLevel) {
            const { data } = await this.createTaxonomyLevel({
              params: {
                taxonomyId,
              },
              data: levelForm,
            });

            updatedTaxonomy = data;
          } else {
            const { data } = await this.updateTaxonomyLevel({
              params: {
                taxonomyId,
                query: {
                  levelId: levelObj.id,
                },
              },
              data: levelForm,
            });

            updatedTaxonomy = data;
          }

          const level = updatedTaxonomy.levels.find(l => l.code === levelObj.code);
          const children = this.levelsGraph.childrenOf(innerLevelId);
          await Promise.all(children.map(async childrenLevelId => reqLevel(childrenLevelId, level.id)));
        };
        const topLevels = this.levelsGraph.nodesInDepth(2);
        await Promise.all(topLevels.map(async levelId => reqLevel(levelId, null)));

        // Create and connect nodes
        const reqNode = async (innerNodeId, realParentId) => {
          const nodeObj = this.nodesGraph.node(innerNodeId);
          const levelObj = this.levelsGraph.node(nodeObj.taxonomyLevelId);
          const realLevel = updatedTaxonomy.levels.find(l => l.code === levelObj.code);
          const createNode = Number(nodeObj.id)
            .toString() === nodeObj.id;

          const nodeForm = {
            parentId: realParentId,
            taxonomyLevelId: realLevel.id,
            code: nodeObj.code,
            description: nodeObj.description,
          };
          if (createNode) {
            const { data } = await this.createTaxonomyNode({
              params: {
                taxonomyId,
              },
              data: nodeForm,
            });

            updatedTaxonomy = data;
          } else {
            const { data } = await this.updateTaxonomyNode({
              params: {
                taxonomyId,
                query: {
                  nodeId: nodeObj.id,
                },
              },
              data: nodeForm,
            });

            updatedTaxonomy = data;
          }

          const node = updatedTaxonomy.nodes.find(n => n.code === nodeObj.code);
          const children = this.nodesGraph.childrenOf(innerNodeId);
          await Promise.all(children.map(async childrenNodeId => reqNode(childrenNodeId, node.id)));
        };

        const topNodes = this.nodesGraph.nodesInDepth(2);
        await Promise.all(topNodes.map(async nodeId => reqNode(nodeId, null)));

        // Delete dangling nodes first
        const danglingNodes = taxonomies[0].nodes
          .filter(n => !this.nodesGraph.nodeList()
            .find(({ id }) => id === n.id));
        await Promise.all(danglingNodes.map(node => this.removeTaxonomyNode({
          params: {
            taxonomyId,
            query: {
              nodeId: node.id,
            },
          },
        })));

        // Delete dangling levels (you cannot delete levels if has nodes)
        const danglingLevels = taxonomies[0].levels
          .filter(l => !this.levelsGraph.nodeList()
            .find(({ id }) => id === l.id));
        await Promise.all(danglingLevels.map(level => this.removeTaxonomyLevel({
          params: {
            taxonomyId,
            query: {
              levelId: level.id,
            },
          },
        })));

        // Refresh list and go back
        await this.refreshTaxonomies();
      } finally {
        this.$store.commit('setLoadingTaxonomies', false);
      }
      this.goBack();
    },
    handleNextStep() {
      if (!this.taxonomyLevelsLocked) {
        this.taxonomyLevelsLocked = true;
      } else {
        this.submit();
      }
    },
    showRemoveModal() {
      this.$bvModal.show('taxonomy-remove-modal');
    },
    handleRemoveModalCancel() {
      this.$bvModal.hide('taxonomy-remove-modal');
    },
    async handleRemoveModalConfirm() {
      await this.removeTaxonomy({
        params: {
          taxonomyId: this.editTaxonomy.id,
        },
      });
      await this.refreshTaxonomies();
    },
  },
  created() {
    if (!this.thisTaxonomy) {
      this.goBack();
      return;
    }
    this.editTaxonomy = { ...this.thisTaxonomy };
    this.taxonomyLevels = this.parseStructure(this.thisTaxonomy.levels);
    this.taxonomyNodes = this.parseStructure(this.thisTaxonomy.nodes);
  },
};
</script>
