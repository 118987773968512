<template>
  <span
    v-b-hover="handleHover"
    class="clipboard-copy"
    @click="copyToClipboard"
  >
    {{ value }}

    <Transition name="fade">
      <i
        v-if="isHovered"
        class="far fa-copy m-1"
      />
    </Transition>

  </span>
</template>

<script>
export default {
  props: {
    value: [Number, String],
  },
  data() {
    return {
      isHovered: false,
    };
  },
  methods: {
    handleHover(hovered) {
      this.isHovered = hovered;
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.value)
        .then(() => {
          this.$bvToast.toast(this.$t('general.copiedToClipboard'), {
            variant: 'success',
            solid: true,
            autoHideDelay: 2000,
            noCloseButton: true,
          });
        });
    },
  },

};
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity .4s !important;
}
.fade-enter, .fade-leave-to  {
  opacity: 0  !important;
}
.clipboard-copy{
    cursor: pointer;
}
.clipboard-copy i {
    opacity: 0.4;
}
.fa-copy {
    position: absolute;
    z-index: 2;
}

</style>
