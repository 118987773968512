<template>
  <div
    class="h-100 d-flex flex-column"
  >
    <div class="d-flex justify-content-center">
      <HorizontalMenu
        :list="routes"
        :selected="page"
        @update:selected="changePage"
      />
    </div>
    <div class="flex-grow-1">
      <RouterView
        class="h-100"
        @selected="page = $event"
      />
    </div>
  </div>
</template>
<script>
import HorizontalMenu from '@core/components/menu/HorizontalMenu';
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    page: null,
  }),
  components: {
    HorizontalMenu,
  },
  computed: {
    ...mapGetters(['tenantId']),
    routes() {
      return [
        {
          key: 'machineLicenses',
          name: this.$t('licenses.machineLicenses'),
          icon: 'fas fa-cogs',
        },
        {
          key: 'tenantLicenses',
          name: this.$t('licenses.tenantLicenses'),
          icon: 'fas fa-address-card',
        },
      ];
    },
  },
  methods: {
    changePage(key) {
      return this.$router.push(`/${this.tenantId}/licenses/${key}`);
    },
  },
  created() {
    this.$emit('page', 'licenses');
  },
};
</script>

<style />
