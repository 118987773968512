<template>
  <div>
    <ClipboardCopy :value="name" />  <br> <small style="color: gray"> <ClipboardCopy :value="idFormatted" /></small>
  </div>
</template>

<script>
import ClipboardCopy from '@core/components/ClipboardCopy';

export default {
  props: {
    id: String,
    name: String,
  },
  components: {
    ClipboardCopy,
  },
  computed: {
    idFormatted() {
      return this.id && this.id.toUpperCase();
    },
  },

};
</script>
