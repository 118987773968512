import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import lang from '@/lang';
import Vue from 'vue';
import { add, update, remove } from '@/utils/utils';
import { elementType } from '@/utils/dictionary';

const structure = new Vapi({
  axios,
  state: {
    plants: [],
    elements: [],
  },
})
  .get({
    action: 'getUserPlants',
    property: 'plants',
    path: 'plants',
  })
  .post({
    action: 'createPlant',
    path: 'plant',
    onSuccess: add('plants'),
  })
  .put({
    action: 'updatePlant',
    path: ({ id }) => `plant/${id}`,
    onSuccess: update('plants'),
  })
  .delete({
    action: 'deletePlant',
    path: ({ id }) => `plant/${id}`,
    onSuccess: remove('plants'),
  })
  .get({
    action: 'getUserElements',
    property: 'elements',
    path: 'elements',
  })
  .post({
    action: 'createElement',
    path: 'element',
    onSuccess: add('elements'),
  })
  .put({
    action: 'updateElement',
    path: ({ id, plantId }) => `plant/${plantId}/element/${id}`,
    onSuccess: (s, { data }) => {
      const idx = s.elements.findIndex(x => x.id === data.id && x.plantId === data.plantId);
      if (idx !== -1) {
        Vue.set(s.elements, idx, data);
      }
    },
  })
  .delete({
    action: 'deleteElement',
    path: ({ id, plantId }) => `plant/${plantId}/element/${id}`,
    onSuccess: remove('elements'),
  })
  .getStore();

structure.getters = {
  elementTypes: () => [
    {
      value: elementType.area,
      text: lang.t('elements.area'),
    },
    {
      value: elementType.line,
      text: lang.t('elements.line'),
    },
    {
      value: elementType.machine,
      text: lang.t('elements.machine'),
    },
  ],
  elementName: s => id => {
    const element = s.elements.find(x => x.id === id);
    return element ? element.name : '?';
  },
  elementType: s => id => {
    const element = s.elements.find(x => x.id === id);
    return element ? element.type : null;
  },
};

export default structure;
