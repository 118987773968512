<template>
  <div
    ref="selectRef"
    class="d-flex align-items-center"
  >
    <div class="select">
      <div
        class="selected-option"
        @click="toggleDropdown"
      >
        {{ selectedTreeNames }}<span :class="{'font-weight-bold': innerValue}">{{ selectedElementName }}</span>
      </div>

      <div
        v-show="dropdownVisible"
        class="select-dropdown"
      >
        <TreeTable
          v-if="taxonomyNodes"
          :selectable="true"
          :columns="columns"
          :list="taxonomyNodes"
          :root="rootTaxonomyNode"
          :selected="selectedNodeId"
          :reduce-disabled="({isAvailableForAssignment}) => !isAvailableForAssignment"
          small-variant
          @select="innerValue = $event"
        />
      </div>
    </div>

    <i
      class="far fa-times-circle ml-2 clear-button"
      @click="clearSelection"
    />
  </div>
</template>

<script>
import TreeTable from '@/components/tables/TreeTable';

export default {
  name: 'TaxonomyElementSelect',
  props: {
    taxonomyNodes: {
      type: Array,
      required: true,
    },
    plant: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    parentId: null,
    dropdownVisible: false,
  }),
  components: { TreeTable },
  computed: {
    innerValue: {
      get() {
        return this.value;
      },
      set(v) {
        this.dropdownVisible = false;
        this.$emit('input', v);
      },
    },
    rootTaxonomyNode() {
      const realRoots = this.taxonomyNodes.filter(o => o.parentId === null);
      if (realRoots.length === 1) return realRoots[0];

      return {
        id: null,
        code: '',
        isAvailableForAssignment: false,
        taxonomyLevelId: null,
      };
    },
    columns() {
      return [{
        key: 'name',
        text: this.$t('general.name'),
        secondary: 'code',
      }];
    },
    selectedTreeNames() {
      if (!this.innerValue) return '';
      let selectedElement = this.taxonomyNodes.find(e => e.id === this.innerValue);
      let name = '';

      while (selectedElement.parentId) {
        selectedElement = this.taxonomyNodes.find(e => e.id === selectedElement.parentId);
        name = `${selectedElement.name || selectedElement.code}/${name}`;
      }

      return name;
    },
    selectedElementName() {
      if (!this.innerValue) return this.$t('taxonomies.pickANode');
      const selectedElement = this.taxonomyNodes.find(e => e.id === this.innerValue);
      return selectedElement.name || selectedElement.code;
    },
    selectedNodeId() {
      return [{ id: this.innerValue }];
    },
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    clearSelection() {
      this.innerValue = '';
      this.dropdownVisible = false;
    },
    handleOutsideClick(ev) {
      if (!this.$refs.selectRef) return;

      if (this.$refs.selectRef.contains(ev.target)) return;
      this.dropdownVisible = false;
    },
  },
  created() {
    window.addEventListener('click', this.handleOutsideClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleOutsideClick);
  },
};
</script>

<style lang="scss" scoped>
.select {
  cursor: pointer;
  display: block;
  width: calc(100% - 24px);
  max-width: calc(100% - 24px);
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  position: relative;
  user-select: none;

  .selected-option {
    padding: 0.375rem 0.95rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    direction: rtl;
    max-width: 95%;
  }

  &-dropdown {
    user-select: none;
    position: absolute;
    left: 0;
    top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(199, 199, 199, 0.25);
    border-radius: 5px;
    padding: 10px;
    z-index: 10;
    width: 150%;
    max-height: 500px;
    overflow-y: auto;
  }
}

.clear-button {
  color: #A6A6A6;
  cursor: pointer;

  &:hover{
    color: black;
  }
}
</style>
