import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';
import { add, update, remove } from '@/utils/utils';

const store = new Vapi({
  axios,
  state: {
    competences: [],
  },
})
  .get({
    action: 'getCompetences',
    property: 'competences',
    path: ({ tenantId }) => `/tenant/${tenantId}/competences`,
  })
  .post({
    action: 'createCompetence',
    path: 'competence',
    onSuccess: add('competences'),
  })
  .patch({
    action: 'updateCompetence',
    path: ({ id }) => `competence/${id}`,
    onSuccess: update('competences'),
  })
  .delete({
    action: 'deleteCompetence',
    path: ({ id }) => `competence/${id}`,
    onSuccess: remove('competences'),
  })
  .getStore();

store.mutations.clearCompetences = s => {
  s.competences = [];
};

export default store;
