<template>
  <Layout
    v-if="tenantId && isReady"
    :page="page"
  >
    <RouterView @page="page = $event" />
  </Layout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Layout from '@/layout/Layout';

export default {
  props: {
    tenantId: String,
  },
  data: () => ({
    page: '',
  }),
  components: {
    Layout,
  },
  computed: {
    ...mapGetters(['isReady', 'usersOnly']),
    ...mapGetters('core', ['can']),
    ...mapGetters('oidcStore', ['oidcIsAuthenticated', 'oidcUser']),
  },
  watch: {
    isReady() {
      this.initialize();
    },
    oidcIsAuthenticated() {
      this.initialize();
    },
    tenantId(id) {
      this.$store.dispatch('changeTenant', id);
      this.initialize();
    },
  },
  methods: {
    ...mapActions([
      'getUsers',
      'getRoles',
      'getCompetences',
      'getUserPlants',
      'getUserElements',
      'getProductConfigs',
      'getAllRoles',
    ]),
    initialize() {
      if (this.tenantId && this.isReady) {
        if (this.can(this.$perm.CONFIG_USER_MANAGEMENT, this.tenantId)
            || this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId)) {
          this.initializeUsers();
        }

        if (!this.usersOnly) {
          this.initializePlants();
          this.initializeProducts();
        }
      }
    },
    initializeUsers() {
      this.getUsers({ params: { tenantId: this.tenantId } });
      this.getRoles({ params: { tenantId: this.tenantId, save: true } });
      this.getCompetences({ params: { tenantId: this.tenantId } });
    },
    initializePlants() {
      this.getUserPlants();
      this.getUserElements();
    },
    initializeProducts() {
      this.getProductConfigs({ params: { tenantId: this.tenantId } });
    },
  },
  created() {
    this.getAllRoles();

    if (this.tenantId) {
      this.initialize();
      this.$store.dispatch('changeTenant', this.tenantId);
    }
  },
};
</script>
