<template>
  <div class="d-flex">
    <div>
      <div class="row">
        <div class="col-4">
          <label>
            {{ $t('auditLogs.tenant') }}
          </label>
          <Multiselect
            v-model="queryParams.tenantId"
            :options="availableTenants"
            track-by="text"
            label="text"
            :preselect-first="true"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('auditLogs.tenant')"
            class="mb-2"
            :class="{'is-danger': !queryParams.tenantId}"
          />
        </div>
        <div class="col-4 ">
          <label>
            {{ $t('general.plant') }}
          </label>
          <Multiselect
            v-model="queryParams.plantIds"
            :multiple="true"
            :options="availablePlants"
            track-by="text"
            label="text"
            :preselect-first="true"
            :close-on-select="availablePlants.length !== 0"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('general.plant')"
          />
        </div>
        <div class="col-4">
          <label>
            {{ $t('auditLogs.userName') }}
          </label>
          <Multiselect
            v-model="queryParams.userId"
            :options="availableUsers"
            track-by="text"
            label="text"
            :preselect-first="false"
            :close-on-select="true"
            :clear-on-select="false"
            :preserve-search="true"
            :placeholder="$t('auditLogs.userName')"
          />
        </div>

        <div class="col-3 p-3">
          <label>
            {{ $t('auditLogs.aggregateId') }}
          </label>
          <BFormInput
            v-model="queryParams.aggregateId"
            style="height:42px"
            :placeholder="$t('auditLogs.aggregateId')"
          />
        </div>

        <div class="col-3 p-3">
          <label>
            {{ $t('auditLogs.type') }}
          </label>
          <BFormInput
            v-model="queryParams.eventType"
            style="height:42px"
            :placeholder="$t('auditLogs.type')"
          />
        </div>

        <div class="col-3 p-3">
          <label>
            {{ $t('auditLogs.dateRange') }}
          </label>

          <DateTimePicker
            id="fromDatePicker"
            v-model="queryParams.from"
            :locale="locale"
            overlay
            enable-button-validate
            format="YYYY-MM-DDTHH:mm:ss"
            formatted="D MMM YYYY HH:mm"
            :label="$t('general.from')"
          />
        </div>

        <div class="col-3 p-3">
          <label class="mt-3" />
          <DateTimePicker
            v-model="queryParams.to"
            :locale="locale"
            overlay
            enable-button-validate
            format="YYYY-MM-DDTHH:mm:ss"
            formatted="D MMM YYYY HH:mm"
            :label="$t('general.to')"
          />
        </div>

        <AuditLogsTable
          class="col-12 pl-0 pr-0"
          :pending="pending"
          :audit-logs="auditLogsMapped"
          :page="page"
          @handlePageChange="handlePageChange($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  CONFIG_AUDIT_LOG_PLANT_ACCESS,
  CONFIG_AUDIT_LOG_TENANT_ACCESS,
} from '@core/dict/permissions';
import DateTimePicker from 'vue-ctk-date-time-picker';
import Multiselect from 'vue-multiselect';
import AuditLogsTable from './auditLogs/AuditLogsTable';

export default {
  name: 'AuditLogs',
  data: () => ({
    page: 1,
    pending: false,
    user: null,
    queryParams: {
      plantIds: [],
      tenantId: null,
      eventType: null,
      aggregateId: null,
      from: null,
      to: null,
      userId: null,
    },
  }),
  components: {
    Multiselect,
    DateTimePicker,
    AuditLogsTable,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapGetters('core', ['locale']),
    ...mapState({
      tenantNames: state => Object.fromEntries(state.tenant.tenants.map(e => [e.id, e.name])),
      plantNames: state => Object.fromEntries(state.structure.plants.map(e => [e.id, e.name])),
      /* eslint-disable no-param-reassign */
      plantsByTenant: state => state.structure.plants.reduce((rv, x) => {
        (rv[x.tenantId] = rv[x.tenantId] || []).push(x.id);
        return rv;
      }, {}),
      auditLogs: state => (state.auditLog.auditLogs.auditLogs || []),
      users: state => state.user.users || [],
      tenantRoles: state => state.role.roles || [],
    }),
    auditLogsMapped() {
      return this.auditLogs.map(auditLog => {
        auditLog.tenantName = this.tenantName(auditLog.tenantId);
        auditLog.plantName = this.plantName(auditLog.plantId);
        auditLog.timeStampPretty = this.prettyPrintTimestamp(auditLog.timeStamp);
        auditLog.serializedEventObject = JSON.parse(auditLog.serializedEvent);
        auditLog.aggregateIdFormatted = auditLog.aggregateId.toUpperCase();
        return auditLog;
      });
    },
    availableTenants() {
      return this.allPermissions
        .filter(x => x.name === CONFIG_AUDIT_LOG_TENANT_ACCESS)
        .map(x => ({
          value: x.value,
          text: this.tenantName(x.value),
        }));
    },
    availablePlants() {
      return this.allPermissions
        .filter(x => x.name === CONFIG_AUDIT_LOG_PLANT_ACCESS)
        .map(x => ({
          value: x.value,
          text: this.plantName(x.value),
        }));
    },
    availableUsers() {
      return this.users.map(u => ({
        value: u.id,
        text: u.email,
      }));
    },
    allPermissions() {
      return this.assignedPermissions.concat(this.inheritedPermissions);
    },
    inheritedPermissions() {
      if (!this.user || !this.user.roles) return [];

      return this.user.roles
        .map(({ roleId }) => this.tenantRoles.find(t => t.id === roleId))
        .filter(x => x)
        .flatMap(x => x.permissions.map(p => ({
          name: p,
          value: x.tenantId,
        })))
        .filter(x => !this.user.permissions.some(y =>
          y.value.toLowerCase() === x.value.toLowerCase()
          && y.name.toLowerCase() === x.name.toLowerCase()));
    },
    assignedPermissions() {
      if (this.user) {
        return this.user?.permissions || [];
      }
      return [];
    },
  },
  watch: {
    queryParams: {
      handler() {
        this.search();
      },
      deep: true,
    },
    'queryParams.tenantId': function handler(value) {
      if (value) {
        this.getTenantUsers();
      }
    },
  },
  methods: {
    ...mapActions(['getUser']),
    ...mapActions(['getUsers']),
    ...mapActions(['getAuditLogs']),
    search() {
      this.page = 1;
      this.retrieveAuditLogs();
    },
    getRequestParams() {
      const query = {};

      query.tenantId = this.queryParams.tenantId && this.queryParams.tenantId.value;
      query.userId = this.queryParams.userId && this.queryParams.userId.value;
      query.plantId = this.queryParams.plantIds.map(x => x.value);
      query.page = this.page;
      if (this.queryParams.eventType) {
        query.type = this.queryParams.eventType;
      }
      if (this.queryParams.aggregateId) {
        query.aggregateId = this.queryParams.aggregateId;
      }
      if (this.queryParams.from) {
        query.from = this.queryParams.from;
      }
      if (this.queryParams.to) {
        query.to = this.queryParams.to;
      }
      return { params: { query } };
    },
    retrieveAuditLogs() {
      this.pending = true;
      this.getAuditLogs(this.getRequestParams())
        .then(() => {
          this.pending = false;
        });
    },
    handlePageChange(value) {
      this.page = value;
      this.retrieveAuditLogs();
    },
    prettyPrintTimestamp(str) {
      let ret = str.substring(0, 19);
      ret = ret.replace(/T/g, ' ');
      return ret;
    },
    tenantName(id) {
      return this.tenantNames[id] ?? '';
    },
    plantName(id) {
      return this.plantNames[id] ?? '';
    },
    clearPlants() {
      this.queryParams.plantIds = [];
    },
    getTenantUsers() {
      this.pending = true;
      this.getUsers({ params: { tenantId: this.queryParams.tenantId.value } })
        .finally(() => {
          this.pending = false;
        });
    },
  },
  created() {
    this.pending = true;
    this.$emit('page', 'auditLog');
    this.getUser({ params: { userId: this.oidcUser.id } })
      .then(({ data }) => {
        this.user = data;
      })
      .finally(() => {
        this.queryParams.tenantId = this.availableTenants[0];
        this.queryParams.plantIds = this.availablePlants;
        this.retrieveAuditLogs();
      });
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style>
.is-danger .multiselect__tags {
  border-color: red;
}
</style>
