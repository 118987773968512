<template>
  <div>
    <div
      v-if="!card || !isActive"
      class="text-center"
    >
      <button
        class="btn btn-sm btn-secondary icon-btn"
        @click="adding = !adding"
      >
        <i
          v-if="!adding"
          class="ion ion-ios-add"
        />
        <i
          v-else
          class="ion ion-ios-close"
        />
      </button>
      <div
        v-if="adding"
        class="pt-1 d-flex"
      >
        <div class="flex-grow-1">
          <input
            v-model="cardId"
            :placeholder="$t('settings.cardId')"
            class="form-control my-1"
          >
          <input
            v-model="cardPin"
            :placeholder="$t('settings.cardPin')"
            class="form-control my-1"
            @input="cutPin"
          >
        </div>
        <div class="px-2 pt-1">
          <button
            v-if="!pending"
            class="btn btn-success icon-btn mt-3"
            @click="addCard"
          >
            <i class="ion ion-ios-checkmark" />
          </button>
          <Loader v-else />
        </div>
      </div>
    </div>
    <div
      v-if="card && !adding"
      class="pt-4 text-center row"
    >
      <div class="col-12 col-md-6">
        <i
          class="ion ion-ios-card"
          style="font-size: 50px"
        />
        <div style="font-size: 25px">
          ***{{ card.lastDigits }}
        </div>
        <div>
          <small v-if="isActive">{{ $t('settings.activeSince') }}:</small>
          <small v-else>{{ $t('settings.inactiveSince') }}:</small>
          <br>
          {{ card.timeStamp | formatDate }}
        </div>
      </div>
      <div
        v-if="!showPinChange && isActive"
        class="col-12 col-md-6 d-flex flex-column justify-content-center"
      >
        <div v-if="!pending">
          <button
            style="width: 140px"
            class="btn btn-sm btn-danger btn-circled px-3"
            @click="lockCard"
          >
            <i class="ion ion-md-lock pr-1" /> {{ $t('settings.blockCard') }}
          </button>
        </div>
        <div
          v-if="!pending"
          class="mt-2"
        >
          <button
            style="width: 140px"
            class="btn btn-sm btn-primary btn-circled px-3"
            @click="showPinChange = true"
          >
            <i class="ion ion-md-keypad pr-1" /> {{ $t('settings.pinChange') }}
          </button>
        </div>
        <Loader v-else />
      </div>
      <div
        v-else-if="showPinChange"
        class="col-12 col-md-6 mt-2 d-flex flex-column justify-content-center"
      >
        <div v-if="!adminMode">
          <input
            v-model="oldPin"
            :placeholder="$t('settings.oldCardPin')"
            class="form-control"
          >
        </div>
        <div>
          <input
            v-model="newPin"
            :placeholder="$t('settings.newCardPin')"
            class="form-control mt-3"
            @input="cutPin"
          >
        </div>
        <div class="mt-2">
          <SaveButton
            :pending="pending"
            size="lg"
            @save="changePin"
            @cancel="cancel"
          />
        </div>
      </div>
    </div>

    <div
      v-if="error"
      class="text-danger text-center small mt-2"
    >
      {{ error }}
    </div>

    <hr v-if="sortedAccessCards.length > 0">
    <div
      v-if="accessCardsPending"
      class="mt-3"
    >
      <Loader />
    </div>
    <div
      v-for="c in sortedAccessCards"
      :key="c.id"
      class="d-flex mt-2 justify-content-between"
    >
      <div>
        <i
          class="ion ion-ios-card"
          style="font-size: 20px"
        />
      </div>
      <div>***{{ c.lastDigits }}</div>
      <div>
        {{ c.timeStamp | formatDate }}
      </div>
      <div>
        <button
          :id="`remove_card_${c.id}`"
          class="btn btn-sm btn-secondary icon-btn"
        >
          <i class="ion ion-md-trash" />
        </button>
        <RemovePopover
          :pending="removePending"
          :target="`remove_card_${c.id}`"
          @remove="deleteCard(c.id)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { handleApiError } from '@/utils/handleApiError';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  props: {
    userId: String,
    adminMode: Boolean,
  },
  data: () => ({
    removeId: null,
    adding: false,
    cardId: null,
    cardPin: null,
    error: null,
    pending: false,
    removePending: false,
    accessCardsPending: false,
    showPinChange: false,
    activeCardPending: false,
    accessCards: [],
    newPin: null,
    oldPin: null,
  }),
  filters: {
    formatDate(date) {
      return moment(date * 1000).format('HH:mm DD-MM-YYYY');
    },
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapState({
      users: state => state.user.users || [],
    }),
    user() {
      if (!this.userId) return {};
      return this.users.find(x => x.id === this.userId);
    },
    card() {
      if (!this.user || !this.user.userAccount) return null;
      return this.user.userAccount.accessCard;
    },
    isActive() {
      if (!this.card) return false;
      return this.card.isActive;
    },
    sortedAccessCards() {
      if (!this.accessCards) return [];
      return this.accessCards.slice().sort((a, b) => b.timeStamp - a.timeStamp);
    },
  },
  methods: {
    ...mapActions([
      'getUserAccessCards',
      'addUserAccessCard',
      'lockUserAccessCard',
      'resetUserAccessCardPin',
      'changeMyAccessCardPin',
      'deleteUserAccessCard',
    ]),
    cutPin() {
      if (this.newPin) {
        this.newPin = this.newPin.substring(0, 4);
      }
      if (this.cardPin) {
        this.cardPin = this.cardPin.substring(0, 4);
      }
    },
    changePin() {
      if (this.newPin && this.newPin.length !== 4) {
        this.error = this.$t('settings.pinLengthError');
        return;
      }
      this.error = null;
      this.pending = true;
      if (this.adminMode) {
        this.resetUserAccessCardPin({
          params: {
            id: this.userId,
          },
          data: {
            newPin: this.newPin,
          },
        })
          .catch(({ response }) => {
            this.error = handleApiError(response)?.title;
          })
          .finally(() => {
            this.pending = false;
            this.showPinChange = false;
            this.newPin = null;
            this.oldPin = null;
          });
      } else if (this.userId === this.oidcUser.id) {
        this.changeMyAccessCardPin({
          data: {
            oldPin: this.oldPin,
            newPin: this.newPin,
          },
        })
          .catch(({ response }) => {
            this.error = handleApiError(response)?.title;
          })
          .finally(() => {
            this.pending = false;
            this.showPinChange = false;
            this.newPin = null;
            this.oldPin = null;
          });
      }
    },
    cancel() {
      this.showPinChange = false;
      this.newPin = null;
      this.oldPin = null;
    },
    lockCard() {
      this.error = null;
      this.pending = true;
      this.lockUserAccessCard({
        params: {
          id: this.userId,
        },
      })
        .catch(({ response }) => {
          this.error = handleApiError(response)?.title;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    addCard() {
      if (this.cardPin && this.cardPin.length !== 4) {
        this.error = this.$t('settings.pinLengthError');
        return;
      }
      if (!this.cardId || this.cardId.length < 4) {
        this.error = this.$t('settings.cardIdLengthError');
        return;
      }
      this.error = null;
      this.pending = true;
      this.addUserAccessCard({
        params: {
          id: this.userId,
        },
        data: {
          cardId: this.cardId,
          pin: this.cardPin,
        },
      })
        .catch(({ response }) => {
          this.error = handleApiError(response)?.title;
        })
        .then(({ data }) => {
          if (data && data.userAccount && data.userAccount.accessCard) {
            this.accessCards.push(data.userAccount.accessCard);
          }
        })
        .finally(() => {
          this.pending = false;
          this.adding = false;
          this.cardId = null;
          this.cardPin = null;
        });
    },
    deleteCard(cardId) {
      this.removePending = true;
      this.deleteUserAccessCard({
        params: {
          id: this.userId,
          cardId,
        },
      })
        .catch(({ response }) => {
          this.error = handleApiError(response)?.title;
        })
        .then(() => {
          this.accessCards = this.accessCards.filter(x => x.id !== cardId);
        })
        .finally(() => {
          this.removePending = false;
        });
    },
  },
  created() {
    if (!this.userId) return;
    this.accessCardsPending = true;
    this.getUserAccessCards({
      params: {
        id: this.userId,
      },
    })
      .then(({ data }) => {
        this.accessCards = data;
      })
      .finally(() => {
        this.accessCardsPending = false;
      });
  },
};
</script>

<style>

</style>
