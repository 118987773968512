import Vue from 'vue';
import VueRouter from 'vue-router';
import { vuexOidcCreateRouterMiddleware } from 'vuex-oidc';

import Home from '@/pages/Home';
import Plants from '@/pages/structure/Plants';
import People from '@/pages/people/People';
import UsersImport from '@/pages/people/UsersImport';
import Products from '@/pages/products/Products';
import KeepUp from '@/pages/products/KeepUp';
import Users from '@/pages/people/Users';
import Competences from '@/pages/people/Competences';
import Registration from '@/pages/registration/Registration';
import MyAccount from '@/pages/settings/MyAccount';
import Licenses from '@/pages/licenses/Licenses';
import MachineLicenses from '@/pages/licenses/MachineLicenses';
import TenantLicenses from '@/pages/licenses/TenantLicenses';
import Taxonomies from '@/pages/taxonomies/Taxonomies';
import TaxonomiesList from '@/pages/taxonomies/TaxonomiesList';
import AuditLog from '@/pages/AuditLog';
import TenantSelect from '@/pages/TenantSelect';
import Roles from '@/pages/people/Roles';
import OidcCallback from '@/pages/auth/OidcCallback';
import TaxonomyDetails from '@/pages/taxonomies/TaxonomyDetails';
import TaxonomyCreate from '@/pages/taxonomies/TaxonomyCreate';
import store from '../store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes: [
    {
      path: '/oidc-callback',
      name: 'oidcCallback',
      component: OidcCallback,
    },
    {
      path: '/:tenantId',
      component: Home,
      props: true,
      children: [
        {
          path: 'account',
          component: MyAccount,
        },
        {
          path: 'plants',
          component: Plants,
        },
        {
          path: 'people',
          component: People,
          redirect: 'people/users',
          children: [
            {
              path: 'users',
              component: Users,
            },
            {
              path: 'roles',
              component: Roles,
            },
            {
              path: 'competences',
              component: Competences,
            },
          ],
        },
        {
          path: 'taxonomies',
          component: Taxonomies,
          redirect: 'taxonomies',
          children: [
            {
              name: 'taxonomies-list',
              path: '',
              component: TaxonomiesList,
            },
            {
              name: 'taxonomies-create',
              path: 'create',
              component: TaxonomyCreate,
            },
            {
              name: 'taxonomies-details',
              path: ':id',
              component: TaxonomyDetails,
            },
          ],
        },
        {
          path: 'products',
          component: Products,
          redirect: 'products/keepup',
          children: [
            {
              path: 'keepup',
              component: KeepUp,
            },
          ],
        },
        {
          path: 'licenses',
          component: Licenses,
          redirect: 'licenses/machineLicenses',
          children: [
            {
              path: 'tenantLicenses',
              component: TenantLicenses,
            },
            {
              path: 'machineLicenses',
              component: MachineLicenses,
            },
          ],
        },
        {
          path: 'auditLog',
          component: AuditLog,
        },
        {
          path: 'settings/account',
          redirect: 'account',
        },
        {
          path: 'people/users/import',
          component: UsersImport,
        },
      ],
    },
    {
      path: '/registration',
      component: Registration,
      meta: {
        isPublic: true,
      },
    },
    {
      path: '/',
      component: TenantSelect,
    },
  ],
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, 'oidcStore'));

export default router;
