import Vapi from 'vuex-rest-api';
import axios from '@/api/axios';

const user = new Vapi({
  axios,
  state: {
    accessCards: [],
  },
})
  .getStore();

export default user;
