export default {
  general: {
    search: 'Search',
    edit: 'Edit',
    confirm: 'Confirm',
    remove: 'Remove',
    cancel: 'Cancel',
    save: 'Save',
    finish: 'Finish',
    name: 'Name',
    add: 'Add',
    ok: 'OK',
    details: 'Details',
    packOs: 'PackOS',
    config: 'ConfigHub',
    workboost: 'WorkBoost',
    keepup: 'KeepUp',
    goodin: 'GoodIn',
    knowlake: 'KnowLake',
    logicprint5: 'LogicPrint5',
    results: 'results',
    noPermissions: 'No permissions',
    plant: 'Plant',
    description: 'Description',
    code: 'Code',
    to: 'To',
    from: 'From',
    copiedToClipboard: 'Copied to clipboard',
    now: 'Now',
    noData: 'No data',
    default: 'Default',
    clone: 'Clone',
    general: 'General',
    iWantToProceed: 'I want to proceed',
    requestReceived: 'Request received',
  },
  menu: {
    userSettings: 'Account settings',
    logout: 'Logout',
  },
  plants: {
    name: 'Name',
    code: 'Code',
    latitude: 'Latitude',
    longitude: 'Longitude',
    structure: 'Plant structure',
    selectPlant: 'Select plant',
  },
  diy: {
    setupStatuses: 'Plant configuration status',
    database: 'Database',
    appService: 'App service',
    deployment: 'Deployment',
    apiPlantsInfoUpdate: 'Adding information about a new plant to the API',
    enforceSetupDoneStatus: 'Enforce done status',
    setupErrorMessage: 'Configuration has failed. Please contact support.',
  },
  elements: {
    area: 'Area',
    line: 'Line',
    machine: 'Machine',
    type: 'Element type',
    location: 'Location',
    addElement: 'Add line/machine/area',
  },
  auth: {
    signOut: 'Sign out',
  },
  pages: {
    plants: 'Plants',
    people: 'People',
    classifications: 'Classifications',
    products: 'Products',
    settings: 'Settings',
    licenses: 'Licenses',
    myAccount: 'My account',
    auditLog: 'Audit log',
  },
  people: {
    users: 'Users',
    roles: 'Roles',
    competences: 'Competences',
    noPermission: 'No user management permission',
  },
  users: {
    add: 'Add user',
    all: 'All users',
    import: 'Import users',
    firstName: 'First name',
    lastName: 'Last name',
    name: 'User name',
    displayName: 'Name',
    email: 'E-mail',
    phone: 'Phone',
    companyEmployeeId: 'Company employee ID',
    new: 'New user',
    roles: 'Roles',
    elementAccess: 'Access to',
  },
  settings: {
    card: 'Card',
    blockCard: 'Block card',
    pinChange: 'Change PIN',
    cardPin: 'Card PIN',
    oldCardPin: 'Current card PIN',
    newCardPin: 'New card PIN',
    cardId: 'Card id',
    activeSince: 'Active since',
    inactiveSince: 'Inactive since',
    pinLengthError: 'PIN must have exactly 4 numbers',
    cardIdLengthError: 'Card ID must have at lest 4 characters',
    help: 'Help',
  },
  account: {
    password: 'Password',
    roles: 'Roles',
    passwordsNotEqual: 'Both passwords must be the same',
    repeatPassword: 'Repeat Password',
    newPassword: 'New password',
    oldPassword: 'Current password',
    claims: 'Permissions',
    edit: 'Account edit',
    usersEdition: 'Users edition',
    selfEditWarning: 'You can\'t edit your own permissions to plants',
    settings: 'My account',
    permissions: 'Permissions',
  },
  error: {
    unknown: 'Unknown error',
    incorrectEmail: 'The e-mail address should be in the following format: example@domain.com',
    emptyInput: 'That field cannot be empty!',
    wrongInput: 'Given data is invalid!',
    passwordsMustBeEqual: 'Passwords must be equal!',
    incorrectCoordinate: 'The coordinate value should be in the following format: 12.34567',
    incorrectCode: 'The code can only contain letters and digits and cannot be longer than 256 characters',
    importNotAvailable: 'Import is not currently available. Please try again later',
    jobFailed: 'There was an error in processing the file',
    listOfErrors: 'List of errors',
    tooLongString: 'The field value is too long',
  },
  competence: {
    add: 'Add competence',
    expiryDate: 'Expiry date',
  },
  role: {
    add: 'Add role',
    manager: 'Manager',
    new: 'New role',
    chooseRole: 'Choose role',
  },
  permissions: {
    inheritedFrom: 'Inherited from ',
    add: 'Add permissions',
    systemAdmin: 'System administrator',
    releaseNotesAccess: 'Release notes',
    choosePermission: 'Choose permission',
    chooseTenant: 'Choose Tenant',
    chooseGroup: 'Choose Group',
    choosePlant: 'Choose plant',
    chooseAsset: 'Choose asset',
    tenantAdminAccess: 'Tenant administrator',
    tenantAuditLogAccess: 'Tenant audit logs access',
    plantAuditLogAccess: 'Plant audit logs access',
    plantElementAccess: 'Plant element access',
    plantAccess: 'Plant access',
    changeDowntimes: 'Changing downtimes',
    elementAccess: 'Element access',
    projectCreateAccess: 'Project create access',
    usersManagementAccess: 'Users management access',
    projectAdminAccess: 'Project administrator',
    projectAccess: 'Project access',
    taskAdminAccess: 'Task administrator',
    taskStatusAccess: 'Task status change access',
    orderManagementAccess: 'Production order management access',
    orderOperationAccess: 'Production order operation access',
    plantAdminAccess: 'Plant administrator',
    writeAccess: 'Access to modify element and its orders',
    readAccess: 'Access to browse element and its orders',
    orderManagement: 'Order management',
    orderViewAccess: 'View orders',
    orderCompleteManualProduction: 'Complete order manual production access',
    orderCompleteEdit: 'Complete order edit access',
    orderInprogressEdit: 'Inprogress order edit access',
    orderLifecycle: 'Order lifecycle management',
    orderEdit: 'Order edit',
    materialManagement: 'Master Material management',
    showSkuList: 'Show SKU list',
    editSkuList: 'Manage SKU',
    statesManagement: 'Edit machine states and rules',
    statesEdit: 'States edit',
    addReason: 'Adding reason',
    addCorrectiveAction: 'Adding corrective action',
    editProblems: 'Problems editing',
    reportAccess: 'Reports access',
    targetConfig: 'Access to editing expected targets',
    plantConfig: 'Plant Configuration',
    configFlow: 'Line structure and connections edit',
    elementsOrder: 'Displaying elements order',
    unitsEdit: 'Units dictionary edit',
    configShifts: 'Shifts timetable and dictionary edit',
    dayStart: 'Day start edit',
    configSignals: 'Signals and properties',
    notificationEdit: 'Notification edit',
    serviceDefinitionManagement: 'Service definition management',
    projectCreate: 'Project create',
    projectAdmin: 'Project administration',
    projectView: 'Display tasks in a project',
    templatesManagement: 'Tasks and tasks group template management',
    questsCreate: 'Quests create',
    questGroupAdmin: 'Group quests management',
    groupQuests: 'Group quests',
    manualDowntime: 'Adding downtimes',
    manualProduction: 'Confirming current production',
    manageDowntimes: 'Downtime management',
    updateSuccessfully: 'Permission update completed successfully.',
    labelling: 'Labelling',
    orders: 'Orders',
    sku: 'SKU',
    labels: 'Labels',
    requests: 'Requests',
    settings: 'Settings',
    planning: 'Planning',
    production: 'Production',
    archive: 'Archive',
    importLog: 'Import log',
  },
  registration: {
    register: 'Register',
    companyName: 'Company name',
    companyDescription: 'Company description',
    nextStep: 'Next step',
    companySetUp: 'Set up your company',
    profileSetUp: 'Set up your profile',
    create: 'Create',
    successful: 'Your company and profile have been successfully created',
    getStarted: 'Get started',
    passwordValidation: 'The password should contain at least 6 characters and two numbers',
    products: 'Select products',
    invalidEmail: 'Invalid email',
    regulations: 'I accept the terms and conditions',
    tryAgain: 'Try again',
    addressUrl: 'Address Url',
  },
  keepUp: {
    workBoostIntegration: 'WorkBoost integration',
    useWorkBoost: 'Use WorkBoost',
    project: 'Project',
    type: 'Type',
    initialState: 'Initial state',
  },
  notify: {
    nothing: 'No notifications',
  },
  auditLogs: {
    tenant: 'Tenant',
    version: 'Version',
    timestamp: 'Timestamp',
    type: 'Event type',
    event: 'Event',
    dateRange: 'When',
    aggregateId: 'Aggregate ID',
    userId: 'User ID',
    userName: 'User',
  },
  table: {
    paginationPrevText: 'Prev',
    paginationNextText: 'Next',
  },
  licenses: {
    tenantLicenses: 'Tenant licenses',
    machineLicenses: 'Machine licenses',
    baseAutomated: 'Base Automated licenses',
    advancedAutomated: 'Advanced Automated extensions',
    entryManual: 'Entry Manual licenses',
    entryDummy: 'Entry Dummy licenses',
    signal: 'Signal extensions',
    vision: 'Vision extensions',
    aggregate: 'Plant aggregate',
    timestamp: 'Machine licenses on',
    year: 'Year',
  },
  import: {
    upload: 'Drop files here to upload',
    notSaved: 'Not saved',
    saved: 'Saved',
    actionRequired: 'Action required',
    checked: 'Verified',
    fillAndAdd: 'Fill and add',
    nothingImported: 'No valid data to import',
    previousImports: 'Previous imports',
    fileIsBeingProcessed: 'File is being processed',
    unableToAssignToRole: 'Failed to assign to the role',
  },
  taxonomies: {
    taxonomy: 'Taxonomy',
    classification: 'Classification',
    classifications: 'Classifications',
    defaultClassification: 'Default classification',
    defaultTaxonomy: 'Default taxonomy',
    assignTo: 'Assign to',
    pickANode: 'Pick a node',
    nodeDisabledForSelection: 'Disabled for assignment',
    continueToAddNodes: 'Continue to add nodes',
    createNewClassification: 'Create new classification',
    factoryStructure: 'Factory structure',
    problems: 'Problems',
    classificationFor: 'Classification for',
    whereToCloneClassification: 'Where do you want to clone this classification?',
    areYouSureAboutEdit: 'Are you sure you want to edit this section?',
    thisCanProblemsInElements: 'This can cause problems with levels in the connection section.',
    doYouWantToRemoveThisClassification: 'Do you want to remove this classification?',
    enterName: 'Enter name',
    enterDescription: 'Enter description',
    enterCode: 'Enter code',
    level: 'Level',
    levels: 'Levels',
    pickLevel: 'Pick level',
    nodeWarning: 'You must select a level for the node. Check if this node can be below this level',
    editLevels: 'Edit levels',
    addLevel: 'Add level',
    nodes: 'Nodes',
    editNodes: 'Edit nodes',
    addNode: 'Add node',
    addLevelToStartEditing: 'Add level to start editing',
    addNodeToStartEditing: 'Add node to start editing',
    classificationTitle: 'Classification title',
    levelLockedTooltip: 'If level is locked, it\'s not possible to assign any problems or elements '
      + 'to nodes inside that level.',
  },
};
