<template>
  <div>
    <div class="d-flex justify-content-center">
      <div class="container">
        <div>
          <div>
            <h4>
              <i class="ion ion-md-paper pr-2" />
              {{ $t('licenses.machineLicenses') }}
            </h4>
          </div>
          <div class="d-flex ">
            <div class="mx-1 w-25">
              <label>
                {{ $t('general.plant') }}:
              </label>
              <BFormSelect
                v-model="plantId"
                :options="tenantPlants"
              />
            </div>
            <div class="mx-1 w-25">
              <label>
                {{ $t('licenses.year') }}:
              </label>
              <BFormSelect
                v-model="year"
                :options="years"
              />
            </div>
          </div>
          <div>
            <LicenseHistory
              :licenses="licenseHistory"
              :timestamp.sync="timestamp"
              style="max-height: 90%"
            />
          </div>
          <div
            v-if="!pending && !pendingHistory"
            class="col-12 col-md-12"
          >
            <div v-if="timestamp">
              <div class="mx-1 mt-4">
                <label>
                  {{ $t('licenses.timestamp') }} {{ timestamp.format('DD/MM/YYYY') }}:
                </label>
              </div>
            </div>
            <div v-if="anyData">
              <table class="table table-hover table-sm">
                <thead>
                  <tr>
                    <td style="width: 40px" />
                    <td
                      style="width: 18%"
                      class="selectable-column-header"
                      :style="!sortBy === 'line' ? 'text-decoration: underline' : ''"
                      @click="toggleSort('line')"
                    >
                      {{ $t('elements.line') }}
                    </td>
                    <td
                      style="width: 12%"
                      class="selectable-column-header"
                      :style="!sortBy === 'entryDummyLicenses' ? 'text-decoration: underline' : ''"
                      @click="toggleSort('entryDummyLicenses')"
                    >
                      {{ $t('licenses.entryDummy') }}
                      <i
                        v-if="sortBy === 'entryDummyLicenses'"
                        class="fas fa-sort-down pl-1"
                        style="position: relative; top: -2px"
                      />
                    </td>
                    <td
                      style="width: 12%"
                      class="selectable-column-header"
                      :style="sortBy === 'entryManualLicenses' ? 'text-decoration: underline' : ''"
                      @click="toggleSort('entryManualLicenses')"
                    >
                      {{ $t('licenses.entryManual') }}
                      <i
                        v-if="sortBy === 'entryManualLicenses'"
                        class="fas fa-sort-down pl-1"
                        style="position: relative; top: -2px"
                      />
                    </td>
                    <td
                      style="width: 12%"
                      class="selectable-column-header"
                      :style="sortBy === 'baseAutomatedLicenses' ? 'text-decoration: underline' : ''"
                      @click="toggleSort('baseAutomatedLicenses')"
                    >
                      {{ $t('licenses.baseAutomated') }}
                      <i
                        v-if="sortBy === 'baseAutomatedLicenses'"
                        class="fas fa-sort-down pl-1"
                        style="position: relative; top: -2px"
                      />
                    </td>
                    <td
                      style="width: 22%"
                      class="selectable-column-header"
                      :style="sortBy === 'advancedAutomatedExtensions' ? 'text-decoration: underline' : ''"
                      @click="toggleSort('advancedAutomatedExtensions')"
                    >
                      {{ $t('licenses.advancedAutomated') }}
                      <i
                        v-if="sortBy === 'advancedAutomatedExtensions'"
                        class="fas fa-sort-down pl-1"
                        style="position: relative; top: -2px"
                      />
                    </td>
                    <td
                      style="width: 12%"
                      class="selectable-column-header"
                      :style="sortBy === 'signalExtensions' ? 'text-decoration: underline' : ''"
                      @click="toggleSort('signalExtensions')"
                    >
                      {{ $t('licenses.signal') }}
                      <i
                        v-if="sortBy === 'signalExtensions'"
                        class="fas fa-sort-down pl-1"
                        style="position: relative; top: -2px"
                      />
                    </td>
                    <td
                      style="width: 12%"
                      class="selectable-column-header"
                      :style="sortBy === 'visionExtensions' ? 'text-decoration: underline' : ''"
                      @click="toggleSort('visionExtensions')"
                    >
                      {{ $t('licenses.vision') }}
                      <i
                        v-if="sortBy === 'visionExtensions'"
                        class="fas fa-sort-down pl-1"
                        style="position: relative; top: -2px"
                      />
                    </td>
                    <td />
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="row in lineLicenses"
                    :key="row.line"
                    @click="selectLine(row)"
                  >
                    <th
                      scope="row"
                    />
                    <td>
                      <span class="font-weight-bold">
                        {{ row.line }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ row.entryDummyLicenses }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ row.entryManualLicenses }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ row.baseAutomatedLicenses }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ row.advancedAutomatedExtensions }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ row.signalExtensions }}
                      </span>
                    </td>
                    <td>
                      <span>
                        {{ row.visionExtensions }}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                    />
                    <td>
                      <span class="font-weight-bold">
                        {{ $t('licenses.aggregate') }}
                      </span>
                    </td>
                    <td>
                      <span class="font-weight-bold">
                        {{ plantLicenses.entryDummyLicenses }}
                      </span>
                    </td>
                    <td>
                      <span class="font-weight-bold">
                        {{ plantLicenses.entryManualLicenses }}
                      </span>
                    </td>
                    <td>
                      <span class="font-weight-bold">
                        {{ plantLicenses.baseAutomatedLicenses }}
                      </span>
                    </td>
                    <td>
                      <span class="font-weight-bold">
                        {{ plantLicenses.advancedAutomatedExtensions }}
                      </span>
                    </td>
                    <td>
                      <span class="font-weight-bold">
                        {{ plantLicenses.signalExtensions }}
                      </span>
                    </td>
                    <td>
                      <span class="font-weight-bold">
                        {{ plantLicenses.visionExtensions }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              v-else
              style="font-size: 18px"
              class="text-center pt-3"
            >
              <div><i class="ion ion-ios-alert" /></div>
              {{ $t('general.noData') }}
            </div>
          </div>
          <div
            v-else-if="pending || pendingHistory || error"
            class="pt-4"
          >
            <Loader :error="error" />
          </div>
        </div>
      </div>
    </div>
    <LModal
      :show.sync="show"
      size="lg"
    >
      <LineLicensesDetailsForm
        :line-id="selectedLineId"
        :machine-licenses="selectedLineData"
        @close="show = false"
      />
    </LModal>
  </div>
</template>

<script>

import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import LineLicensesDetailsForm from '@/components/licenses/LineLicensesDetailsForm';
import LicenseHistory from '@/components/charts/LicenseHistory';

export default {
  data: () => ({
    licensesData: [],
    licenseHistory: [],
    pending: false,
    pendingHistory: false,
    error: null,
    sortBy: 'line',
    plantId: null,
    year: null,
    years: [],
    timestamp: null,
    selectedLineId: null,
    selectedLineData: null,
    show: false,
  }),
  components: {
    LineLicensesDetailsForm,
    LicenseHistory,
  },
  computed: {
    ...mapGetters(['elementName']),
    ...mapGetters(['tenantId']),
    ...mapGetters('core', ['locale', 'can']),
    ...mapState({
      plants: state => state.structure.plants || [],
    }),
    lineLicenses() {
      if (!this.licensesData) return [];
      return this.licensesData
        .filter(x => !x.lineId && x.elementId !== x.plantId)
        .map(x => ({
          ...x,
          line: this.elementName(x.elementId),
        }))
        .sort((a, b) => {
          if (this.sortBy === 'line') {
            return a.line.localeCompare(b.line);
          }
          return b[this.sortBy] - a[this.sortBy];
        });
    },
    plantLicenses() {
      const plantLicenses = this.licensesData?.find(x => x.elementId === this.plantId);
      if (!plantLicenses) {
        return {
          entryDummyLicenses: 0,
          entryManualLicenses: 0,
          baseAutomatedLicenses: 0,
          advancedAutomatedExtensions: 0,
          signalExtensions: 0,
          visionExtensions: 0,
        };
      }
      return plantLicenses;
    },
    aggregate() {
      return {
        entryDummyLicenses: this.lineLicenses.reduce((a, x) => a + x.entryDummyLicensesCount, 0),
        entryManualLicenses: this.lineLicenses.reduce((a, x) => a + x.entryManualLicensesCount, 0),
        automatedBaseLicenses: this.lineLicenses.reduce((a, x) => a + x.automatedBaseLicensesCount, 0),
        automatedAdvancedLicenses: this.lineLicenses.reduce((a, x) => a + x.automatedAdvancedLicensesCount, 0),
        visionLicenses: this.lineLicenses.reduce((a, x) => a + x.visionLicensesCount, 0),
      };
    },
    maxTimestampInAYear() {
      const startOfToday = moment().startOf('day');
      const startOfLastDayOfYear = moment({ year: this.year }).endOf('year').startOf('day');
      return moment.min(startOfToday, startOfLastDayOfYear);
    },
    maxTimestampInHistory() {
      if (!this.licenseHistory || this.licenseHistory.length === 0) {
        return moment({ year: this.year }).startOf('year').startOf('day');
      }
      const maxDate = this.licenseHistory
        .reduce((max, obj) => (obj.date > max ? obj.date : max), this.licenseHistory[0].date);
      return moment(maxDate);
    },
    anyData() {
      return this.licensesData !== null
        && this.licensesData.length > 0;
    },
    tenantPlants() {
      const sortBy = { path: 'down' };

      const sortString = (key, a, b, force) => {
        if (sortBy[key] === 'down' || force) {
          return a[key].localeCompare(b[key]);
        }
        if (sortBy[key] === 'up') {
          return b[key].localeCompare(a[key]);
        }
        return 0;
      };

      return this.plants
        .filter(x =>
          this.can(this.$perm.CONFIG_PLANT_ADMIN, x.id)
          || this.can(this.$perm.CONFIG_TENANT_ADMIN, x.tenantId))
        .sort((a, b) => sortString('name', a, b) || sortString('name', a, b, true))
        .map(x => ({
          text: x.name || x.code,
          value: x.id,
        }));
    },
  },
  watch: {
    plantId() {
      this.timestamp = this.maxTimestampInAYear;
      this.requestPlantLicenseHistory();
      this.requestPlantLicenses();
    },
    year() {
      this.timestamp = this.maxTimestampInAYear;
      this.requestPlantLicenseHistory();
      this.requestPlantLicenses();
    },
    timestamp() {
      this.requestPlantLicenses();
    },
    tenantPlants(v) {
      if (v.length > 0) {
        if (this.plantId) return;
        this.plantId = v[0].value;
      }
    },
  },
  methods: {
    ...mapActions([
      'getElementLicenses',
      'getPlantLicenseHistory',
    ]),
    fillInYears() {
      const currentYear = moment().year();

      const yearsArray = [];
      for (let year = 2021; year <= currentYear; year += 1) {
        yearsArray.push(year);
      }

      this.years = yearsArray.map(x => ({
        text: x,
        value: x,
      }));
    },
    toggleSort(v) {
      if (this.sortBy !== v) {
        this.sortBy = v;
      } else {
        this.sortBy = null;
      }
    },
    requestPlantLicenses() {
      if (this.pending || !this.plantId || !this.timestamp) return;
      this.pending = true;
      this.getElementLicenses({
        params: {
          plantId: this.plantId,
          timestamp: moment(this.timestamp)
            .endOf('day')
            .toISOString(),
        },
      })
        .then(({ data }) => {
          this.licensesData = data;
        })
        .catch(({ response: { data } }) => {
          this.pending = false;
          this.error = data;
        })
        .finally(() => {
          this.pending = false;
        });
    },
    requestPlantLicenseHistory() {
      if (this.pendingHistory || !this.plantId || !this.timestamp) return;
      this.pendingHistory = true;
      this.getPlantLicenseHistory({
        params: {
          plantId: this.plantId,
          timestamp: moment(this.timestamp)
            .endOf('day')
            .toISOString(),
        },
      })
        .then(({ data }) => {
          this.licenseHistory = data;
          this.trimTimestampToMaxHistoryDate();
        })
        .catch(({ response: { data } }) => {
          this.pendingHistory = false;
          this.error = data;
        })
        .finally(() => {
          this.pendingHistory = false;
        });
    },
    trimTimestampToMaxHistoryDate() {
      if (!this.licensesData || this.licensesData.length === 0) return;
      const maxDate = this.licensesData
        .reduce((max, obj) => (obj.date > max ? obj.date : max), this.licensesData[0].date);
      this.timestamp = moment.min(this.timestamp, moment(maxDate));
    },
    selectLine(lineData) {
      this.selectedLineId = lineData.elementId;
      this.selectedLineData = this.licensesData.filter(x => x.lineId === lineData.elementId);
      this.show = true;
    },
  },
  mounted() {
    this.fillInYears();
    this.year = moment().year();

    if (this.tenantPlants.length > 0) {
      if (this.plantId) return;
      this.plantId = this.tenantPlants[0].value;
    }
  },
  created() {
    const plants = this.plants.filter(x => x.tenantId === this.tenantId);
    this.plantId = plants.length > 0 ? plants[0].id : null;
    this.timestamp = moment()
      .startOf('day');
    this.$emit('selected', 'machineLicenses');
  },
};
</script>

<style lang="scss" scoped>
td {
  font-size: 13px;
}

.selectable-column-header {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.color-box-container {
  display: table-cell;
  width: 30px;
  vertical-align: middle;
  min-height: 20px;
  height: 100%
}

table {
  margin-top: 30px;
}

select {
  height: 42px;
}
</style>
