<template>
  <div>
    <div class="h4">
      <i class="icon-role pr-2" />
      {{ name || $t('role.new') }}
    </div>
    <div>
      <HorizontalMenu
        :selected.sync="page"
        :list="rolePages"
      />
    </div>

    <div
      v-if="page === 'details'"
      class="p-2"
    >
      <form
        class="text-left"
        @submit.prevent="save"
      >
        <label class="mb-0 small font-weight-bold">
          {{ $t('general.name') }}
        </label>
        <BFormInput
          v-model="name"
          class="mb-2"
        />
        <div class="mt-3">
          <SaveButton
            :pending="pending"
            :remove-pending="removePending"
            :disabled="submitDisabled"
            :show-remove="!!role"
            @cancel="$emit('close')"
            @remove="remove()"
          />
        </div>
      </form>
    </div>
    <div
      v-else
      class="mt-3"
    >
      <RolePermissionsForm
        :role-id="role.id"
        :subject-type="role.subjectType"
        :show-only-role-permission="true"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { handleApiError } from '@/utils/handleApiError';
import HorizontalMenu from '@core/components/menu/HorizontalMenu';
import RolePermissionsForm from '@/components/permissions/RolePermissionsForm';

export default {
  props: {
    role: Object,
  },
  data: () => ({
    pending: false,
    removePending: false,
    error: null,
    id: '',
    name: '',
    page: 'details',
  }),
  components: {
    RolePermissionsForm,
    HorizontalMenu,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapGetters(['tenantId']),
    rolePages() {
      let pages = [{
        key: 'details',
        name: this.$t('general.details'),
      }];
      if (this.role) {
        pages = [...pages, {
          key: 'permission',
          name: this.$t('account.permissions'),
        }];
      }
      return pages;
    },
    submitDisabled() {
      return !this.name;
    },
    roleToUpdate() {
      return {
        id: this.id,
        name: this.name,
      };
    },
  },
  methods: {
    ...mapActions(['createRole', 'updateRole', 'deleteRole']),
    save() {
      if (this.role) {
        this.update();
      } else {
        this.create();
      }
    },
    update() {
      this.pending = true;
      this.updateRole({
        params: {
          id: this.id,
        },
        data: this.$patch(this.role, this.roleToUpdate, false),
      })
        .then(() => {
          this.$emit('close');
        })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    create() {
      this.pending = true;
      const newRole = {
        tenantId: this.tenantId,
        name: this.name,
      };
      this.createRole({
        data: newRole,
      })
        .then(() => {
          this.$emit('close');
        })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
        });
    },
    remove() {
      this.removePending = true;
      this.deleteRole({
        params: {
          id: this.id,
        },
      })
        .catch(({ response }) => {
          handleApiError(response);
        })
        .finally(() => {
          this.pending = false;
          this.$emit('close');
        });
    },
  },
  created() {
    if (this.role) {
      this.id = this.role.id;
      this.name = this.role.name;
    }
  },
};
</script>

<style>

</style>
