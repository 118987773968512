<template>
  <div class="sidebar">
    <div
      v-for="r in sidebar"
      :key="r.key"
      class="route d-flex flex-column justify-content-center"
      :class="{ selected: r.key === page }"
      @click="select(r)"
    >
      <div class="icon">
        <i :class="r.icon" />
      </div>
      <div class="text">
        {{ r.text }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  CONFIG_USER_MANAGEMENT,
  CONFIG_PLANT_ADMIN,
  CONFIG_TENANT_ADMIN,
  CONFIG_AUDIT_LOG_TENANT_ACCESS,
} from '@core/dict/permissions';

export default {
  props: {
    page: String,
  },
  computed: {
    ...mapGetters('oidcStore', ['oidcUser']),
    ...mapGetters('core', ['canAny', 'isSysAdmin']),
    ...mapGetters(['usersOnly', 'tenantId']),
    sidebar() {
      return [
        {
          key: 'myAccount',
          icon: 'fas fa-user',
          text: this.$t('pages.myAccount'),
          route: `/${this.tenantId}/account`,
          show: true,
        },
        {
          key: 'people',
          icon: 'fas fa-users',
          text: this.$t('pages.people'),
          route: `/${this.tenantId}/people`,
          show: true,
          require: [
            CONFIG_TENANT_ADMIN,
            CONFIG_USER_MANAGEMENT,
          ],
        },
        {
          key: 'plants',
          icon: 'fas fa-industry',
          text: this.$t('pages.plants'),
          route: `/${this.tenantId}/plants`,
          show: !this.usersOnly,
          require: [
            CONFIG_PLANT_ADMIN,
            CONFIG_TENANT_ADMIN,
          ],
        },
        {
          key: 'taxonomies',
          icon: 'fas fa-project-diagram',
          text: this.$t('pages.classifications'),
          route: `/${this.tenantId}/taxonomies`,
          show: !this.usersOnly,
          require: [
            CONFIG_TENANT_ADMIN,
          ],
        },
        !this.isSysAdmin ? { key: null } : {
          key: 'licenses',
          icon: 'fas fa-address-card',
          text: this.$t('pages.licenses'),
          route: `/${this.tenantId}/licenses`,
          show: !this.usersOnly,
          require: [
            CONFIG_TENANT_ADMIN,
          ],
        },
        !this.canAny(CONFIG_AUDIT_LOG_TENANT_ACCESS) ? { key: null } : {
          key: 'auditLog',
          icon: 'fa fa-history',
          text: this.$t('pages.auditLog'),
          route: `/${this.tenantId}/auditLog`,
          show: !this.usersOnly,
          require: [
            CONFIG_AUDIT_LOG_TENANT_ACCESS,
          ],
        },
      ]
        .filter(x => x.show)
        .filter(x => (!x.require || x.require.some(r => this.canAny(r))) && x.key !== null);
    },
  },
  methods: {
    select(r) {
      this.$router.push(r.route)
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
  .sidebar {
    width: 80px;
    background-color: rgba(150, 150, 150, 0.2);
    margin-top: -60px;
    padding-top: 60px;
    min-height: 100vh;
    padding-left: 3px;
    padding-right: 3px;
  }

  .route {
    text-align:  center;
    margin: 12px 0;
    cursor: pointer;
    width: 70px;
    height: 70px;
    border-radius: 50%;

    .text {
      font-size: 10px;
      font-weight: bold;
      line-height: 1;
      padding-top: 4px;
    }

    .icon {
      font-size: 27px;
      line-height: 1.1;
      transition: transform 200ms;
      color: rgba(#333, 0.7);
    }

    &:hover, &.selected {
      background-color: rgba(#333, 0.1);

      .icon {
        color: #333;
      }
    }

    &:hover .icon {
      transform: scale(1.2);
    }
  }

</style>
