<template>
  <Apexcharts
    ref="chart"
    type="line"
    height="500"
    :options="options"
    :series="series"
    @click="handleClick"
  />
</template>

<script>
import moment from 'moment';
import Apexcharts from 'vue-apexcharts';

export default {
  props: {
    licenses: Array,
  },
  components: {
    Apexcharts,
  },
  computed: {
    options() {
      const categories = this.licenses.map(x => moment.utc(x.date).format('DD/MM/YYYY'));
      const labels = {
        trim: true,
        showDuplicates: false,
        hideOverlappingLabels: true,
        maxHeight: 150,
      };
      const chart = {
        toolbar: {
          show: false,
        },
      };
      const colors = ['#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#2E294E'];
      return {
        chart,
        colors,
        stroke: {
          width: 3,
          curve: 'smooth',
        },
        xaxis: {
          categories,
          labels,
          tickAmount: 15,
        },
        yaxis: {
          tickAmount: 1,
        },
      };
    },
    series() {
      const ret = [];
      const entryDummy = {
        name: this.$t('licenses.entryDummy'),
        type: 'line',
        data: this.licenses.map(x => x.entryDummyLicenses),
      };
      ret.push(entryDummy);
      const entryManual = {
        name: this.$t('licenses.entryManual'),
        type: 'line',
        data: this.licenses.map(x => x.entryManualLicenses),
      };
      ret.push(entryManual);
      const baseAutomated = {
        name: this.$t('licenses.baseAutomated'),
        type: 'line',
        data: this.licenses.map(x => x.baseAutomatedLicenses),
      };
      ret.push(baseAutomated);
      const advancedAutomated = {
        name: this.$t('licenses.advancedAutomated'),
        type: 'line',
        data: this.licenses.map(x => x.advancedAutomatedExtensions),
      };
      ret.push(advancedAutomated);
      const signal = {
        name: this.$t('licenses.signal'),
        type: 'line',
        data: this.licenses.map(x => x.signalExtensions),
      };
      ret.push(signal);
      const vision = {
        name: this.$t('licenses.vision'),
        type: 'line',
        data: this.licenses.map(x => x.visionExtensions),
      };
      ret.push(vision);
      return ret;
    },
  },
  methods: {
    handleClick(event, chartContext, config) {
      if (!config || config.dataPointIndex === -1) return;
      const { date } = this.licenses[config.dataPointIndex];
      this.$emit('update:timestamp', moment(date));
    },
  },
};
</script>
