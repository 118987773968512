<template>
  <div class="pb-5">
    <div class="d-flex justify-content-between page-header">
      <div class="d-flex text-left align-items-center title">
        <i class="icon-user pr-2" />
        {{ $t('people.users') }}
      </div>
      <div class="d-flex align-items-center">
        <button
          v-if="canAdd"
          class="btn btn-success btn-sm mr-2 action-button"
          @click="selected = null; form = true"
        >
          <i class="fas fa-add pr-1" />
          {{ $t('users.add') }}
        </button>
        <button
          v-if="canAdd"
          class="btn btn-success btn-sm action-button"
          @click="showImport()"
        >
          <i class="fas fa-file-upload pr-1" />
          {{ $t('users.import') }}
        </button>
      </div>
    </div>

    <div class="px-3">
      <div class="mb-2 d-flex align-items-end">
        <BFormInput
          ref="search"
          v-model="search"
          type="search"
          :placeholder="`${$t('general.search')}...`"
          class="search"
          @click="$refs.search.select()"
        />

        <ElementDropdown
          class="ml-2"
          @plantId="selectedPlantId = $event; getTenantUsers()"
          @elementId="selectedElementId = $event; getTenantUsers()"
        />
      </div>
      <SimpleTable
        :items="filteredUsers"
        :fields="columns"
        sort-by="lastName"
        class="user-table"
        @row-clicked="rowClicked"
      />
    </div>

    <LModal
      :show.sync="form"
      size="lg"
    >
      <UserForm
        :user="selected"
        @close="form = false"
        @create="selected = $event"
      />
    </LModal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import UserForm from '@/components/people/users/UserForm';
import SimpleTable from '@core/components/SimpleTable';
import ElementDropdown from '@/components/elements/ElementDropdown';
import createSearch from '@core/utils/search';

export default {
  data: () => ({
    selected: null,
    form: false,
    passForm: false,
    permissionsForm: false,
    cardForm: false,
    search: '',
    pending: false,
    selectedPlantId: null,
    selectedElementId: null,
  }),
  components: {
    UserForm,
    SimpleTable,
    ElementDropdown,
  },
  computed: {
    ...mapGetters(['tenantId']),
    ...mapGetters('core', ['can']),
    ...mapState({
      plants: state => state.structure.plants || [],
      users: state => state.user.users || [],
      allRoles: state => state.role.roles || [],
    }),
    usersWithRoles() {
      const roleDict = this.allRoles.reduce((acc, curr) => {
        acc[curr.id] = curr;
        return acc;
      }, {});

      const plantDict = this.plants
        .filter(x => x)
        .reduce((acc, curr) => {
          acc[curr.id.toLowerCase()] = curr;
          return acc;
        }, {});

      return this.users
        .map(x => ({
          ...x,
          name: `${x.firstName || ''} ${x.lastName || ''}`,
          plantNames: (x.plantIds || [])
            .map(id => plantDict[id.toLowerCase()]?.name)
            .filter(y => y)
            .join(', '),
          role: (x.roles || [])
            .map(y => roleDict[y.roleId]?.name)
            .filter(y => y)
            .join(', '),
        }));
    },
    filteredUsers() {
      const search = createSearch(this.search);

      return this.usersWithRoles
        .filter(x =>
          search(`${x.lastName || ''} ${x.firstName || ''}`)
          || search(`${x.firstName || ''} ${x.lastName || ''}`)
          || search(x.plantNames)
          || search(x.email)
          || search(x.role));
    },
    canAdd() {
      return this.can(this.$perm.CONFIG_USER_MANAGEMENT, this.tenantId)
        || this.can(this.$perm.CONFIG_TENANT_ADMIN, this.tenantId);
    },
    columns() {
      return [
        {
          key: '',
        },
        {
          key: 'lastName',
          label: this.$t('users.lastName'),
          sortable: true,
          thStyle: {
            width: '20%',
          },
        },
        {
          key: 'firstName',
          label: this.$t('users.firstName'),
          sortable: true,
          thStyle: {
            width: '20%',
          },
        },
        {
          key: 'email',
          label: this.$t('users.email'),
          sortable: true,
          thStyle: {
            width: '20%',
          },
        },
        {
          key: 'role',
          label: this.$t('people.roles'),
          thStyle: {
            width: '20%',
          },
        },
        {
          key: 'plantNames',
          label: this.$t('pages.plants'),
        },
      ];
    },
  },
  watch: {
    users() {
      this.selected = this.users.find(u => u?.id === this.selected?.id);
    },
  },
  methods: {
    ...mapActions(['getUsers']),
    getTenantUsers() {
      this.getUsers({
        params: {
          tenantId: this.tenantId,
          query: {
            plantId: this.selectedPlantId,
            elementId: this.selectedElementId,
          },
        },
      });
    },
    rowClicked(e) {
      this.selected = e;
      this.form = true;
    },
    showImport() {
      this.$router.push(`/${this.tenantId}/people/users/import`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../page-style.scss";

.card {
  background-color: rgba(153, 163, 172, 1);
  display: inline;
  color: white;

  &:hover {
    background-color: rgba(123, 136, 149, 1);
    color: white;
  }
}

.active-card {
  background-color: $ilabo;
  color: white;

  &:hover {
    background-color: rgba(137, 157, 7, 1);
    color: white;
  }
}
</style>
