<template>
  <div>
    <div class="col-12">
      <table class="table mt-4">
        <thead>
          <tr>
            <th>{{ $t('auditLogs.tenant') }}</th>
            <th>{{ $t('general.plant') }}</th>
            <th>{{ $t('auditLogs.aggregateId') }}</th>
            <th>{{ $t('auditLogs.type') }}</th>
            <th>{{ $t('auditLogs.version') }}</th>
            <th>{{ $t('auditLogs.timestamp') }}</th>
            <th>{{ $t('auditLogs.userName') }}</th>
          </tr>
        </thead>

        <tbody v-if="pending">
          <tr>
            <td colspan="7">
              <Loader />
            </td>
          </tr>
        </tbody>

        <tbody v-else>
          <tr
            v-for="(auditLog, index) in auditLogs"
            :id="`auditLogRow_${index}`"
            :key="index"
          >
            <td>
              <ObjectDisplay
                :id="auditLog.tenantId"
                :name="auditLog.tenantName"
              />
            </td>
            <td>
              <ObjectDisplay
                :id="auditLog.plantId"
                :name="auditLog.plantName"
              />
            </td>
            <td> <ClipboardCopy :value="auditLog.aggregateIdFormatted" /> </td>
            <td>
              <a
                class="text-primary"
                style="cursor: pointer;"
                @click="showJsonModal(index)"
              >{{ auditLog.type }}</a>
            </td>
            <td>
              <ClipboardCopy :value="auditLog.version" />
            </td>
            <td>
              <ObjectDisplay
                :id="auditLog.timeStamp"
                :name="auditLog.timeStampPretty"
              />
            </td>
            <td>
              <ObjectDisplay
                :id="auditLog.userId"
                :name="auditLog.userName"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div
      v-if="count > pageSize"
      class="col-12 mt-2"
    >
      <BPagination
        v-model="localPage"
        :total-rows="count"
        :per-page="pageSize"
        :prev-text="$t('table.paginationPrevText')"
        :next-text="$t('table.paginationNextText')"
        @change="$emit('handlePageChange', $event)"
      />
    </div>

    <LModal
      :show.sync="jsonModal"
      size="lg"
    >
      <div class="text-center ">
        <b>{{ selectedAuditLog.type }}</b>
      </div>

      <VueJsonPretty :data="selectedAuditLog.serializedEventObject" />
    </LModal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import VueJsonPretty from 'vue-json-pretty';
import ClipboardCopy from '@core/components/ClipboardCopy';
import ObjectDisplay from './ObjectDisplay';
import 'vue-json-pretty/lib/styles.css';

export default {
  props: {
    auditLogs: {
      type: Array,
      required: true,
    },
    page: {
      type: Number,
      required: true,
    },
    pending: Boolean,
  },
  data: () => ({
    pageSize: 20,
    localPage: 1,
    jsonModal: false,
    selectedAuditLog: {},
  }),
  components: {
    VueJsonPretty, ObjectDisplay, ClipboardCopy,
  },
  computed: {
    ...mapGetters('core', ['locale']),
    ...mapState({
      count: state => state.auditLog.auditLogs.totalItems || 0,
    }),
  },
  watch: {
    page(value) {
      this.localPage = value;
    },
  },
  methods: {
    showJsonModal(index) {
      this.jsonModal = true;
      this.selectedAuditLog = this.auditLogs[index];
    },
  },

};
</script>

<style >
  tr{
    white-space: nowrap;
  }
  .tooltip-json{
    white-space: pre-wrap
  }
</style>
