<template>
  <div>
    <h1
      class="text-muted mb-4"
      style="cursor: pointer;"
      @click="goBack"
    >
      <i class="fas fa-circle-chevron-left" />
    </h1>

    <BRow>
      <BCol lg="6">
        <TaxonomyInfoForm
          :description.sync="description"
          :namespace-id.sync="namespaceId"
          :code.sync="code"
          :tags.sync="tags"
          :code-input-disabled="levelsLocked"
          :namespace-id-input-disabled="levelsLocked"
        />
      </BCol>
    </BRow>

    <hr>

    <TaxonomyLevelsTree
      v-model="taxonomyLevels"
      :disabled="levelsLocked"
    />

    <hr>

    <div v-if="levelsLocked">
      <TaxonomyNodesTree
        v-model="taxonomyNodes"
        :nodes-graph="nodesGraph"
        :levels-graph="levelsGraph"
      />

      <hr>
    </div>

    <div class="mt-4">
      <BButton
        class="rounded-pill px-5 ml-2"
        @click="goBack"
      >
        {{ $t('general.cancel') }}
      </BButton>

      <BButton
        class="rounded-pill px-5 ml-2"
        variant="success"
        :disabled="nextStepDisabled"
        @click="handleNextStep"
      >
        <span v-if="!levelsLocked">
          {{ $t('taxonomies.continueToAddNodes') }}
        </span>

        <span v-else>
          <i class="fas fa-check mr-1" />
          {{ $t('general.save') }}
        </span>
      </BButton>
    </div>
  </div>
</template>

<script>
import TaxonomyInfoForm from '@/components/taxonomies/form/TaxonomyInfoForm';
import TaxonomyLevelsTree from '@/components/taxonomies/TaxonomyLevelsTree';
import TaxonomyNodesTree from '@/components/taxonomies/TaxonomyNodesTree';
import Graph from '@core/utils/Graph';
import { mapActions } from 'vuex';

export default {
  name: 'TaxonomyCreate',
  inject: [
    'refreshTaxonomies',
  ],
  data: () => ({
    description: '',
    namespaceId: '',
    code: '',
    tags: [],
    levelsLocked: false,
    taxonomyLevels: [],
    taxonomyNodes: [],
  }),
  components: {
    TaxonomyNodesTree,
    TaxonomyLevelsTree,
    TaxonomyInfoForm,
  },
  computed: {
    levelsGraph() {
      const graph = this.taxonomyLevels.reduce((acc, curr) => {
        acc.edges.push({
          from: null,
          to: curr.data.id,
        });

        const rec = node => {
          acc.nodes.push(node.data);
          acc.edges.push(...node.children.map(c => ({
            from: node.data.id,
            to: c.data.id,
          })));
          if (node?.children?.length) {
            node.children.forEach(c => rec(c));
          }
        };
        rec(curr);

        return acc;
      }, {
        edges: [],
        nodes: [],
      });
      return new Graph(
        'levels',
        graph.nodes,
        graph.edges,
        null,
      );
    },
    nodesGraph() {
      const graph = this.taxonomyNodes.reduce((acc, curr) => {
        acc.edges.push({
          from: null,
          to: curr.data.id,
        });

        const rec = node => {
          acc.nodes.push(node.data);
          acc.edges.push(...node.children.map(c => ({
            from: node.data.id,
            to: c.data.id,
          })));
          if (node?.children?.length) {
            node.children.forEach(c => rec(c));
          }
        };
        rec(curr);

        return acc;
      }, {
        edges: [],
        nodes: [],
      });
      return new Graph(
        'nodes',
        graph.nodes,
        graph.edges,
        null,
      );
    },
    nextStepDisabled() {
      if (this.levelsLocked) {
        const nodeLengthCheck = this.nodesGraph.nodeList().length === 0;
        const nodeValidCheck = this.nodesGraph.nodeList()
          .some(n => n.code === '' || n.taxonomyLevelId === '');
        const nodesValidLevelCheck = this.nodesGraph.nodeList()
          .some(n => !this.levelsOfNode(n)
            .find(({ id }) => id === n.taxonomyLevelId));
        return nodeLengthCheck
          || nodeValidCheck
          || nodesValidLevelCheck;
      }

      const codeCheck = this.code === '';
      const plantIdCheck = this.namespaceId === '';
      const tagsCheck = this.tags.length === 0;
      const levelsLengthCheck = this.levelsGraph.nodeList().length === 0;
      const levelsValidCheck = this.levelsGraph.nodeList()
        .some(n => n.code === '');
      return codeCheck
        || plantIdCheck
        || tagsCheck
        || levelsLengthCheck
        || levelsValidCheck;
    },
  },
  methods: {
    ...mapActions([
      'createTaxonomy',
      'removeTaxonomy',
      'createTaxonomyLevel',
      'createTaxonomyNode',
      'getTaxonomiesById',
    ]),
    levelsOfNode(node) {
      const parentId = this.nodesGraph.parent(node?.data?.id || node.id);
      if (parentId) {
        const parent = this.nodesGraph.node(parentId);
        const levelsIds = this.levelsGraph.childrenOf(parent.taxonomyLevelId);
        return levelsIds.map(id => this.levelsGraph.node(id));
      }
      const depth = this.nodesGraph.depth(node?.data?.id || node.id);
      return this.levelsGraph.nodesInDepth(depth)
        .map(id => this.levelsGraph.node(id));
    },
    goBack() {
      const rootId = this.$route.path.split('/')[1];
      this.$router.push(`/${rootId}/taxonomies`);
    },
    async submit() {
      this.$store.commit('setLoadingTaxonomies', true);

      try {
        // Create taxonomy
        const newTaxonomyForm = {
          description: this.description,
          namespaceId: this.namespaceId,
          code: this.code,
          isHierarchical: true,
          tags: this.tags,
        };
        const { data: createdTaxonomy } = await this.createTaxonomy({
          data: newTaxonomyForm,
        });
        const createdTaxonomyId = createdTaxonomy.id;

        // Create and connect levels
        const reqLevel = async (innerLevelId, realParentId) => {
          const levelObj = this.levelsGraph.node(innerLevelId);
          const createLevelForm = {
            parentId: realParentId,
            code: levelObj.code,
            description: levelObj.description,
            policyType: 1, // None = 0, CanAddItem = 1, Roslyn = 2 TODO: level policies
            policy: levelObj.policy,
          };
          await this.createTaxonomyLevel({
            params: {
              taxonomyId: createdTaxonomyId,
            },
            data: createLevelForm,
          });
          const { data: updatedTaxonomy } = await this.getTaxonomiesById({
            params: {
              query: {
                ids: [createdTaxonomyId],
                namespaceId: this.namespaceId,
              },
            },
          });
          const createdLevel = updatedTaxonomy[0].levels.find(l => l.code === levelObj.code);
          const children = this.levelsGraph.childrenOf(innerLevelId);
          await Promise.all(children.map(async childrenLevelId => reqLevel(childrenLevelId, createdLevel.id)));
        };
        const topLevels = this.levelsGraph.nodesInDepth(2);
        await Promise.all(topLevels.map(async levelId => reqLevel(levelId, null)));

        // Create and connect nodes
        const reqNode = async (innerNodeId, realParentId) => {
          const nodeObj = this.nodesGraph.node(innerNodeId);
          const levelObj = this.levelsGraph.node(nodeObj.taxonomyLevelId);
          const { data: preTaxonomy } = await this.getTaxonomiesById({
            params: {
              query: {
                ids: [createdTaxonomyId],
                namespaceId: this.namespaceId,
              },
            },
          });
          const realLevel = preTaxonomy[0].levels.find(l => l.code === levelObj.code);
          const createNodeForm = {
            parentId: realParentId,
            taxonomyLevelId: realLevel.id,
            code: nodeObj.code,
            description: nodeObj.description,
          };
          await this.createTaxonomyNode({
            params: {
              taxonomyId: createdTaxonomyId,
            },
            data: createNodeForm,
          });
          const { data: updatedTaxonomy } = await this.getTaxonomiesById({
            params: {
              query: {
                ids: [createdTaxonomyId],
                namespaceId: this.namespaceId,
              },
            },
          });
          const createdNode = updatedTaxonomy[0].nodes.find(n => n.code === nodeObj.code);
          const children = this.nodesGraph.childrenOf(innerNodeId);
          await Promise.all(children.map(async childrenNodeId => reqNode(childrenNodeId, createdNode.id)));
        };
        const topNodes = this.nodesGraph.nodesInDepth(2);
        await Promise.all(topNodes.map(async nodeId => reqNode(nodeId, null)));

        // Refresh list
        await this.refreshTaxonomies();

        // Go back to list
        this.goBack();
      } finally {
        this.$store.commit('setLoadingTaxonomies', false);
      }
    },
    handleNextStep() {
      if (!this.levelsLocked) {
        this.levelsLocked = true;
      } else {
        this.submit();
      }
    },
  },
};
</script>
